<!--
 * @Description: 课程资源管理
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:43:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-11 17:08:20
-->
<template>
  <div class="course-resources">
    <table-list
      title="资源列表"
      :loading="loading"
      :data="svLists"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '发布短视频',
    method: _this.handleAdd,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '短视频名称',
    prop: 'videoName',
  },
  {
    label: '短视频分类',
    type: 'treeSelect',
    prop: 'categoryId',
    showLevels: false,
    options: _this.CategoryList,
    optionProps: {
      value: 'categoryId',
      label: 'name',
      children: 'children',
    },
  },
  {
    label: '短视频状态',
    type: 'select',
    prop: 'releaseStatus',
    children: [
      { value: 1, label: '未发布' },
      { value: 2, label: '已发布' },
    ],
  },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'time',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'id',
    label: '视频编号',
  },
  {
    prop: 'coverImageUrl',
    label: '视频封面',
    render: (h, { row }) => {
      return [
        row.coverImageUrl
          ? h('img', {
              domProps: { src: row.coverImageUrl },
              style: 'height: 50px;width:80px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'videoName',
    label: '视频标题',
  },
  {
    prop: 'categoryId',
    label: '分类',
    formatter: row => {
      return {
        87: '党建',
        88: '哲学',
        89: '历史',
        90: '经济',
        91: '管理',
        92: '政法',
        93: '科技',
        94: '三农',
        96: '职业',
        97: '行业',
        98: '教育',
        99: '艺术',
        100: '军事',
      }[row.categoryId]
    },
  },
  {
    prop: 'releaseUserName',
    label: '发布人',
  },
  {
    prop: 'duration',
    label: '视频时长(秒)',
  },
  {
    prop: 'bindProductName',
    label: '跳转课程',
  },
  {
    prop: 'watchNum',
    label: '播放次数',
  },
  {
    prop: 'fullPlayNum',
    label: '完整播放次数',
  },
  {
    prop: 'fullPlayRate',
    label: '完播率(%)',
  },
  {
    prop: 'uploadTime',
    label: '创建时间',
  },
  {
    prop: 'releaseStatus',
    label: '状态',
    formatter: row => {
      return {
        1: '未发布',
        2: '已发布',
      }[row.releaseStatus]
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import { svCategory, svList } from '@/api/center'
import to from 'await-to'
export default {
  name: 'VideoManger',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      CategoryList: [],
      svLists: [],
      selectList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        categoryId: '',
        releaseStatus: '',
        uploadTimeEnd: '',
        uploadTimeStart: '',
        videoName: '',
      },
    }
  },
  mounted() {
    this.svCategory()
    this.svList()
  },
  methods: {
    //
    async svCategory() {
      const [res, err] = await to(svCategory())
      if (err) return this.$message.warning(err.msg)
      this.CategoryList = res.data
    },
    async svList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(svList(this.pager))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.svLists = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.pager.videoName = queryParams.videoName
      this.pager.releaseStatus = queryParams.releaseStatus
      this.pager.categoryId = queryParams.categoryId[0]
      if (queryParams.time && queryParams.time !== 0) {
        this.pager.uploadTimeStart = queryParams.time[0]
        this.pager.uploadTimeEnd = queryParams.time[1]
      } else {
        this.pager.uploadTimeStart = ''
        this.pager.uploadTimeEnd = ''
      }
      this.svList()
    },
    // 新增
    handleAdd() {
      this.$router.push('/scenter/videoManger/add')
    },
    // 编辑
    handleEdit(val) {
      this.$router.push({ path: '/scenter/videoManger/add', query: { id: val.id } })
    },
    // 删除
    async handleDelete() {
      // if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的学员')
      // let studentIds = []
      // this.selectStudents.map(item => studentIds.push(item.studentId))
      // this.$confirm('确认删除资源吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(async () => {
      //   const [, err] = await to(studentRemove({ studentIds }))
      //   if (err) return this.$message.warning(err.msg)
      //   this.$message.success('删除成功')
      //   this.getCourseResource()
      // })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.svList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.svList()
    },
  },
}
</script>
